function replaceBulk(str, findArray, replaceArray) {
    
   // console.log(str);
   // console.log(findArray);
   // console.log(replaceArray);
    var result = str;
    for (i = 0; i < findArray.length; i++) {
        result = str.replace(findArray[i], replaceArray[i]);
        /*var pieces = str.split(findArray[i]);
        console.log(pieces);
        pieces[0] = replaceArray[i];
        result = pieces.join('');*/
    }
    return result;
    //console.log('RESULT',result);
    /*var i, regex = [], map = {};
    for (i = 0; i < findArray.length; i++) {
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
        map[findArray[i]] = replaceArray[i];
    }

    regex = regex.join('|');
    str = str.replace(new RegExp(regex, 'g'), function (matched) {
        return map[matched];
    });

    return str;*/
}

$(document).ready(function(){

    if ($('.tab-pane').length > 0) {

        $('.tab-pane').each(function() {

            if($(this).find('input[name*="locale"]').length > 0){
                var locale = $(this).find('input[name*="locale"]').val();
                // var from = ['page[', 'general[', 'gallery[', 'slider_config['];
                // var to = ['page[' + locale + '][', 'general[' + locale + '][', 'gallery[' + locale + '][', 'slider_config[' + locale + ']['];
                var from = ['['];
                var to = ['[' + locale + ']['];

                $(this).find('input, select, textarea').each(
                    function(index) {
                        var name = replaceBulk( $(this).attr('name'), from, to);
                        $(this).attr('name', name);
                    }
                );
            }
            

        });

    }

});