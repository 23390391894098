export function darkMode() {
  if (localStorage.getItem('darkMode') == "true") {
    $('#darkMode').prop('checked', true);
    $('body').addClass('dark-mode');

    if ($('.form-group .text_widget').length) {
      CKEDITOR.config.bodyClass = "dark-mode";  
      CKEDITOR.addCss('body.dark-mode{background:#303030; color: rgba(255,255,255, 0.8);}');
    }
  } else {
    $('#darkMode').prop('checked', false);
    $('body').removeClass('dark-mode');
    if ($('.form-group .text_widget').length) {
      CKEDITOR.config.bodyClass = "";
    }
  }
}

export function darkModeSwitch() {
  if ($('#darkMode').is(':checked')) {
    localStorage.setItem('darkMode', 'true');
    $('body').addClass('dark-mode');
    if ($('.cke').length) {
      for(var instanceName in CKEDITOR.instances){
        CKEDITOR.instances[instanceName].updateElement();
      }
      CKEDITOR.config.bodyClass = "dark-mode";
      CKEDITOR.addCss('body{background:#303030; color: rgba(255,255,255, 0.8);}');
      
      reinitCKE()
    
    }
  } else {
    localStorage.setItem('darkMode', 'false');
    $('body').removeClass('dark-mode');
    if ($('.cke').length) {
      CKEDITOR.config.bodyClass = "";
      CKEDITOR.addCss('body{background:#ffffff; color: rgba(255,255,255, 0.8);}');
    }
  }

}

