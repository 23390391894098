// loads the Bootstrap jQuery plugins
/*import 'bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition.js';*

import 'popper.js/dist/popper';
import 'popper.js/dist/popper-utils';*/
import 'bootstrap/dist/js/bootstrap.js';

// loads the code syntax highlighting library
//import './highlight.js';
