var SimplePopup = require('../vendor/simplepopup/simplePopup.js');

$(document).ready(function(){

    $.each($('input[name*="field_name"]'),function(k,v){
        var name = $(this).val();
        var label = $(this).parent().parent().find('label')[0]
        $(label).html(name);
    })
    if($('input[name*="field_name"]').length){
        createEntityButton();
    }





    var existing_fields = [];
    $('input[name$="[field_name]"]').each(function() {
        existing_fields.push($(this).val());
    });

    var ignore_fields = ['item_category', 'parent_category'];

    $(document).on('change', '#page-category select[name="page_category[page_entity]"]', function () {
        $('.entity-properties').remove();

        $.ajax({
            method: 'POST',
            url: $(this).parents('form').data('url'),
            data: $(this).serialize(),
            success: function (data) {

                var html = '';
                var prototype = $('#page_category_page_category_config').attr('data-prototype');

                //$.each(JSON.parse(data), function(i, item) {
                $.each(data[0], function(i, item) {
                    if ($.inArray(item, existing_fields) == -1 && $.inArray(item, ignore_fields) == -1) {
                        html += prototype.replace(/__name__label__/g, item).replace(/__name__/g, i);
                    }
                });

                $('#page_category_object_class').val(data[1][0]);

                //console.log("$('#page_category_object_class').val(" + data[1][0] + ");");

                $('div#page_category_page_category_config').before($('<div class="entity-properties"></div>'));
                $('.entity-properties').html(html);

                $('input[name$="[field_name]"]').each(function() {
                    if ($(this).val() == '') {
                        //var field_name = $(this).parents('.form-group').find('label.control-label.required').text();
                        var field_name = $(this).parent().siblings('label.control-label.required').text();
                        $(this).val(field_name);
                    }
                });

                //console.log(data);
                createEntityButton();
                
            },
            fail: function () {
                alert('Fail!');
            },
            error: function () {
                alert('Error!');
            },
        });
    });

    if ($('.page-category-edit').length > 0) {
        $('#page-category select[name="page_category[page_entity]"]').trigger('change');
    }



function createEntityButton(){
    var site_url = window.location.protocol+'//'+window.location.host;

    /*Dodavanje buttona*/
    $('input[value*="fgkey"]').each(function (i, el) {
        $(el).parent().append('<div class="form-group entity-select"><button>Odaberi poveznicu</button></div>');
    });

    $.each($(".form-group .entity-select"),function(){
        var entity = $(this).parent().find('input[id*="entity"]').val();
        var sysTitle =$(this).parent().find('input[id*="sys_title"]').val();
        $(this).find('.tmp-info-span').remove();
        if(entity.length !=0 && sysTitle.length == 0){
            sysTitle = 'Slika';
        }
        $(this).append('<span class="tmp-info-span">'+sysTitle+'</span>');

    });

    /*dodavanje eventa na button*/
        $(document).on('click','.entity-select',function(e){
            e.preventDefault();
            var select = $('#page_category_parent').html();
            var _this = this;
            var popupHtml = "<select id='entity'><option value='image'>Slika</option>"+select+"</select><select id='select-type'><option value=''>Select display type</option><option value='dropdown'>Dropdown</option><option value='multy-checkbox'>Multiple checkbox</option></select><br /><button id='choose'>Odaberi</button>";
            new SimplePopup({
                'contentClass': 'entity-select-popup',
                'content': {
                    'url':false,
                    'data':popupHtml
                },
                'contentCss':{
                    'width': '30%',
                },
                'optionalData':_this,
                onAfterContentShow: function (el, self) {
                    $(el).find('#select-type').hide();
                    $(el).find('#entity option[value=""]').remove();
                    //btn click
                    $(el).on('click','#choose',function(e){
                        e.preventDefault();

                        var selectValue = $(el).find('#entity').val();
                        var selectType = $(el).find('#select-type').val();
                        
                        if(selectValue == 'image'){
                            $(self.settings.optionalData).parent().find('input[id*="entity"]').val("App\\Component\\Cms\\Entity\\Media");
                            $(self.settings.optionalData).find('.tmp-info-span').remove();
                            $(self.settings.optionalData).append('<span class="tmp-info-span">Slika</span>')
                            self.removePopup(self);
                            return;
                        }

                        url = site_url + "/admin/dev/page/category/ajax/getInfo/"+selectValue;

                        $.post(url,{id:selectValue})
                            .done(function(data) {

                                $(self.settings.optionalData).parent().find('input[id*="entity"]').val(data.entity);
                                $(self.settings.optionalData).parent().find('input[id*="sys_title"]').val(data.sys_title);
                                $(self.settings.optionalData).parent().find('input[id*="select_type"]').val(selectType);

                                $(self.settings.optionalData).find('.tmp-info-span').remove();
                                $(self.settings.optionalData).append('<span class="tmp-info-span">'+data.sys_title+'</span>')
                                self.removePopup(self);
                            })
                            .fail(function(data) {
                                console.log('fail', data);
                                alert('Error');
                            });
                    });
                    //end btn click
                    $(el).on('change','#entity',function(){
                            
                            var _val = $(this).val();
                            
                            if(_val != 'image'){
                                $('#select-type').show();
                            }else{
                                $('#select-type').hide();
                            }

                        });
                }
            });
        })
}

});